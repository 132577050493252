/*--------------------------------------------------------------------------*
 * mv
 *--------------------------------------------------------------------------*/
$(function(){
    var lastSlide;
    
    $('#top-mv').on('init', function(e, slick){
        lastSlide = slick.slideCount - 1;
    }).slick({
        dots: false,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 1000,
        lazyLoad: 'progressive',
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false
    }).on('afterChange', function(e, slick){
        if (slick.currentSlide === lastSlide) {
            slick.slickPause();
            $('#skip').hide();
            $('#replay').css('display', 'block');
        }
    });

    $('#skip').on('click', function(){
        $('#top-mv').slick('slickGoTo', lastSlide);
    });
    $('#replay').on('click', function(){
        $('#top-mv').slick('slickGoTo', 0);
        $('#top-mv').slick('slickPlay');
        // もし再生が始まらない場合は以下のように書いてみる。autoplayの数値は適宜変更
        // $('#top-mv').slick('setOption', 'autoplaySpeed', 0).slick('slickPlay').slick('setOption', 'autoplaySpeed', 2500);
        
        $('#replay').hide();
        $('#skip').css('display', 'block');
    });

    $('.js-floating-close').on('click', function () {
        $('.top-mv-floating').fadeOut();
    });
});
